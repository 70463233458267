<template>
  <div class="flex flex-col w-full">
    <card class="w-full flex flex-col mt-2 p-5">
      <div class="w-full gap-5 mb-6 flex">
        <Card class="w-3/6 py-6 px-4">
          <div class="flex justify-between">
            <Icon
              class-name="text-white h-11 "
              icon-name="people-union"
              size="l"
            />
            <div class="flex flex-col h-11">
              <span class="text-xs font-semibold uppercase text-jet"
                >Pending Requests</span
              >
              <span class="text-lg font-extrabold text-carrotOrange">{{
                totalPending
              }}</span>
            </div>
            <div class="border bg-white w-0" />
            <div class="flex flex-col h-11">
              <span class="text-xs font-semibold uppercase text-jet"
                >Approved Requests</span
              >
              <span class="text-lg font-extrabold text-mediumSeaGreen">{{
                totalApproved
              }}</span>
            </div>
            <div class="border bg-white w-0" />
            <div class="flex flex-col h-11">
              <span class="text-xs font-semibold uppercase text-jet"
                >Disapproved Requests</span
              >
              <span class="text-lg font-extrabold text-desire">{{
                totalDisapproved
              }}</span>
            </div>
          </div>
        </Card>
      </div>
      <template v-slot:footer>
        <CardFooter
          buttoncard
          buttontext="New Advance Request"
          module="advanceAndLoan"
          printcard
          reloadcard
          @modalRequest="$router.push({ name: 'EssNewAdvance' })"
          @reload="reload()"
        />
      </template>
    </card>
    <card class="p-5 mt-1">
      <div class="flex-col">
        <div class="w-full">
          <div class="w-full flex flex-col">
            <Table
              v-if="requests.length > 0 || loadingData"
              :headers="advanceHeader"
              :items="requests"
              aria-label="advance table"
              :loading="loadingData"
              :has-number="false"
              class="w-full mt-4 mb-4"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.type" class=" capitalize">
                  {{ item.data.type }}
                </div>
                <div v-if="item.createdAt">
                  {{
                    $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy")
                  }}
                </div>
                <div v-else-if="item.interestRate">
                  {{ `${item.data.interestRate}%` }}
                </div>
                <div v-else-if="item.principalAmount">
                  {{ convertToCurrency(item.data.principalAmount) }}
                </div>
                <div v-else-if="item.interestAmount">
                  {{ convertToCurrency(item.data.interestAmount) }}
                </div>
                <div v-else-if="item.repaymentAmount">
                  {{ convertToCurrency(item.data.repaymentAmount) }}
                </div>
                <div class=" tw-text-center" v-else-if="item.repaymentDuration">
                  {{ `${item.data.repaymentDuration}` }}
                </div>
                <div
                  v-else-if="item.approvalStatus"
                  style="width: 100px"
                  class="p-3"
                  :class="{
                    pending: item.data.approvalStatus === 'pending',
                    approved: item.data.approvalStatus === 'approved',
                    disapproved: item.data.approvalStatus === 'disapproved'
                  }"
                >
                  <div class="w-auto capitalize flex flex-col ">
                    <p class="text-sm font-semibold ">
                      {{ item.data.approvalStatus }}
                    </p>
                  </div>
                </div>
                <div v-else-if="item.id">
                  <div
                    class="cursor-pointer flex"
                    @click="openSideModal(item.data.id)"
                  >
                    <icon
                      icon-name="icon-eye"
                      size="xsm"
                      class="-mt-1 -ml-2 text-blueCrayola"
                      style="padding: 3px 10px; width: 36px; height: 36px;"
                    />
                  </div>
                </div>
              </template>
            </Table>
            <div
              v-else
              class="w-full flex flex-col mt-4 p-2 justify-center items-center"
            >
              <p class="text-base my-2">
                There are no pending advance requests at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </card>

    <RightSideBar v-if="openModal" @close="openModal = false" close-button>
      <template v-slot:title>
        <p class="text-darkPurple">
          Advance Timeline
        </p>
      </template>
      <div style="height: 100%" class="mt-60" v-if="loadingModal">
        <loader size="xxs" :loader-image="false" />
      </div>
      <div v-else>
        <div class="flex flex-col pt-2 space-y-5">
          <div class=" flex">
            <div class=" w-5/12 ">
              <span
                class=" w-6/12 inline-block text-xs text-romanSilver font-semibold  uppercase"
              >
                Category:
              </span>
              <span class=" text-sm text-darkPurple font-semibold w-full">
                {{ advanceTimeline.paySetting }}
              </span>
            </div>
            <div class=" w-7/12  pl-3 border-l ml-2">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Request Type:
              </span>
              <span class=" text-sm text-darkPurple font-semibold  w-full">
                {{ advanceTimeline.advanceType }}
              </span>
            </div>
          </div>
          <div class="flex">
            <div class=" w-5/12 ">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Intrest Rate:
              </span>
              <span class=" text-sm text-darkPurple font-semibold w-full">
                {{ advanceTimeline.rate }}%
              </span>
            </div>
            <div class=" w-7/12  pl-3 border-l ml-2">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Repayment Period:
              </span>
              <span class=" text-sm text-darkPurple font-semibold  w-full">
                {{ advanceTimeline.duration }} Month(s)
              </span>
            </div>
          </div>
          <div class="flex">
            <div class=" w-5/12 ">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Amount Req:
              </span>
              <span
                class=" text-sm text-darkPurple font-semibold w-full break-words"
              >
                {{ convertToCurrency(advanceTimeline.amount) }}
              </span>
            </div>
            <div class=" w-7/12  pl-3 border-l ml-2">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                First Repayment:
              </span>
              <span class=" text-sm text-darkPurple font-semibold w-full">
                {{
                  $DATEFORMAT(
                    new Date(advanceTimeline.repaymentTime),
                    "MMMM yyyy"
                  )
                }}
              </span>
            </div>
          </div>
          <div class="flex">
            <div class=" w-5/12 ">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Outstanding:
              </span>
              <span class=" text-sm text-darkPurple font-semibold  w-full">
                {{ convertToCurrency(outstandingAmount) }}
              </span>
            </div>
            <div class=" w-7/12  pl-3 border-l ml-2">
              <span
                class=" inline-block text-xs text-romanSilver font-semibold  uppercase w-6/12"
              >
                Request Date:
              </span>
              <span class=" text-sm text-darkPurple font-semibold  w-full">
                {{
                  $DATEFORMAT(
                    new Date(advanceTimeline.requestDate),
                    "dd-MM-yyyy"
                  )
                }}
              </span>
            </div>
          </div>
          <div class="pt-3">
            <span
              class=" inline-block text-xs text-romanSilver font-semibold  uppercase "
              >Approval Status:</span
            >
            <span
              class="mx-2 py-1 px-3 rounded text-sm font-semibold capitalize"
              :class="bgColor(advanceTimeline.status)"
            >
              {{ advanceTimeline.status }}
            </span>
          </div>
          <div class=" border border-dashed" />
          <div class=" overflow-y-visible">
            <div class="flex flex-col py-1 px-2">
              <card
                class=" w-full py-4 mb-3 px-3 space-y-3"
                v-for="(lists, index) in repaymentSchedule"
                :key="index"
              >
                <div>
                  <span
                    class="inline-block pr-6 text-romanSilver font-semibold text-sm"
                  >
                    Month:
                  </span>
                  <span
                    class="text-darkPurple font-semibold text-base float-right"
                  >
                    {{ $DATEFORMAT(new Date(lists.scheduleDate), "MMMM yyyy") }}
                  </span>
                </div>
                <div>
                  <span
                    class="  inline-block pr-6 text-romanSilver font-semibold text-sm"
                  >
                    Repayment amount:
                  </span>
                  <span
                    class=" text-darkPurple font-semibold text-base float-right"
                  >
                    {{ convertToCurrency(lists.amount) }}
                  </span>
                </div>
                <div>
                  <span
                    class="  inline-block pr-6 text-romanSilver font-semibold text-sm"
                  >
                    Status:
                  </span>
                  <span class=" inline-block text-base float-right">
                    <span
                      class="bage px-2 py-1 rounded text-sm font-semibold capitalize"
                      v-if="lists.status === 'approved'"
                    >
                      {{ lists.status }}
                    </span>
                    <span
                      class="bg-Pend px-2 py-1 rounded text-sm font-semibold capitalize"
                      v-else
                    >
                      {{ lists.status }}
                    </span>
                  </span>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";

export default {
  name: "EssAdvance",
  components: {
    Card,
    Icon,
    CardFooter,
    Table,
    RightSideBar
  },
  data() {
    return {
      openModal: false,
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
      loadingData: true,
      loadingModal: false,
      requests: [],
      totalPending: "--",
      totalApproved: "--",
      totalDisapproved: "--",
      approvedRequest: [],
      disApprovedRequest: [],
      advanceTimeline: [],
      repaymentSchedule: [],
      pendingLength: 0,
      approvedLength: 0,
      disapprovedLength: 0,
      outstandingAmount: "",
      type: "advance",
      advanceHeader: [
        { title: "REQUEST TYPE", value: "type" },
        { title: "DATE REQUESTED", value: "createdAt" },
        { title: "PRINCIPAL AMNT.", value: "principalAmount" },
        { title: "INTEREST RATE (P/A)", value: "interestRate" },
        { title: "INTEREST AMNT", value: "interestAmount" },
        { title: "TOTAL REPAYABLE", value: "repaymentAmount" },
        { title: "REPAYMENT PERIOD", value: "repaymentDuration" },
        { title: "Status", value: "approvalStatus" },
        { title: "", value: "id", image: true }
      ]
    };
  },
  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getRequests(`type=${this.type}${pageNumber}${itemPage}`);
    },
    getRequests(query) {
      this.loadingData = true;
      this.$_getEmployeeAdvanceAndLoan({ id: this.$AuthUser.id, query }).then(
        result => {
          const requestsData = result.data.requests;
          this.requests = requestsData;
          this.metaData = result.data.meta;

          // TODO: get these from backend
          this.totalPending = requestsData.filter(
            request => request.approvalStatus === "pending"
          ).length;
          this.totalApproved = requestsData.filter(
            request => request.approvalStatus === "approved"
          ).length;
          this.totalDisapproved = requestsData.filter(
            request => request.approvalStatus === "disapproved"
          ).length;

          this.loadingData = false;
        }
      );
    },
    reload() {
      this.getRequests(`type=${this.type}&page=1&perPage=50`);
    },
    openSideModal(id) {
      this.openModal = true;
      this.loadingModal = true;
      this.$_getAdvanceAndLoanRequest(id).then(result => {
        const timelineData = {
          paySetting: result.data.request.paySetting.nameOnPayslip,
          rate: result.data.request.interestRate,
          amount: result.data.request.principalAmount,
          repayment: result.data.request.repaymentAmount,
          advanceType: result.data.request.type,
          duration: result.data.request.repaymentDuration,
          repaymentTime: result.data.request.firstRepaymentDate,
          requestDate: result.data.request.createdAt,
          status: result.data.request.approvalStatus
        };
        this.advanceTimeline = timelineData;

        const scheduleData = result.data.request.repaymentSchedule.map(v => ({
          scheduleDate: v.repaymentDate,
          amount: v.totalAmount,
          status: v.paymentStatus
        }));
        this.repaymentSchedule = scheduleData;

        let outstandingsum = 0;

        scheduleData.filter(a => {
          if (a.status === "pending") {
            outstandingsum += a.amount;
          }
          return {};
        });

        this.outstandingAmount = outstandingsum;
        this.loadingModal = false;
      });
    },

    bgColor(data) {
      if (data === "pending") {
        return "bg-Pend";
      }

      if (data === "approved") {
        return "bg-Appr";
      }

      if (data === "disapproved") {
        return "text-desire bg-light-flame-red";
      }

      return {};
    }
  },
  computed: {
    hasSubscriptionExpired() {
      return this.$store.getters.hasSubscriptionExpired;
    }
  },
  mounted() {
      this.getRequests(`type=${this.type}&page=1&perPage=50`);
  }
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
.bage {
  background-color: rgba(19, 181, 106, 0.15);
  color: rgba(19, 181, 106, 1);
}
.bg-Pend {
  background-color: rgba(233, 147, 35, 0.15);
  color: rgba(233, 147, 35, 1);
}
.bg-Appr {
  background-color: rgba(19, 181, 106, 0.15);
  color: rgba(19, 181, 106, 1);
}
</style>
